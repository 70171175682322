import * as Dicom from '../State/Dicom'
import * as Patient from '../State/Patient'
import * as Api from '../Util/Api'
import { takeLatest, takeEvery, call, put, select } from 'redux-saga/effects'
import { normalizeName } from '../Util/Format'
import { apiUrl } from '../Util/Config'

let abortUploadController;
let abortDownloadController;

export function* retrieveStudies({ payload: { studies } }) {
  try {
    yield getJWTToken()
    const accessToken = yield select(Dicom.selectJWTToken)
    for (let studyId of studies) {
      const rawStudyDatas = yield call(Api.getStudy, accessToken, studyId)
      const studyDatas = yield dicomDatas(rawStudyDatas)
      yield put(Dicom.addStudy({ [studyId]: { ...studyDatas } }))
    }
  } catch (error) {
    yield put(Dicom.apiError(error.message))
  }
}

export function* dicomDatas(rawStudyDatas) {
  const data = yield select(Patient.selectData)
  const study = rawStudyDatas[0]
  const [modalities, date, UID, description] = ['00080061', '00080020', '0020000D', '00081030']
  const studyModalities = study[modalities] ? study[modalities].Value.filter(m => m !== 'SR') : []
  const studyDescription = study[description] ? study[description].Value : ''
  const studyDate = study[date] ? study[date].Value[0] : ''
  const studyUid = study[UID] ? study[UID].Value[0] : ''
  const studyFormattedDate = studyDate
    ? [studyDate.slice(6, 8), studyDate.slice(4, 6), studyDate.slice(0, 4)].join('-')
    : ''
  const formattedStudyDate = studyDate
    ? studyFormattedDate
    : 'Date non définie'
  const fileName = [
    normalizeName(data.firstname.value),
    normalizeName(data.lastname.value),
    studyModalities.join('-'),
    studyDate
  ].filter(e => e).join('_')

  return {
    studyModalities,
    studyDescription,
    studyUid,
    formattedStudyDate,
    fileName
  }
}

export function* abortUpload() {
  abortUploadController.abort();
  yield call(reset, Dicom.UPLOAD)
}

export function* abortDownload() {
  abortDownloadController.abort();
  yield call(reset, Dicom.DOWNLOAD)
}

export function* reset(type) {
  yield put(Dicom.showLoadingBox({ type, isVisible: false }))
  yield put(Dicom.initUpload())
  yield put(Dicom.fail())
}

export function* uploadStudy(file) {
  try {
    const accessToken = yield select(Dicom.selectJWTToken)
    yield put(Dicom.showLoadingBox({ type: Dicom.UPLOAD, downloadType: 'dicom', isVisible: true }))

    const study = yield call(Api.sendDicom, accessToken, file, { signal: abortUploadController.signal })
    yield put(Dicom.incrementUploaded())
    return study
  } catch (error) {
    yield put(Dicom.incrementUploadedError())
    yield put(Dicom.apiError(error.message))
  }
}

export function* getStudyIdFromXml(study) {
  const xml = yield new window.DOMParser().parseFromString(study, "text/xml")
  const studyUrl = yield xml.querySelector('NativeDicomModel > DicomAttribute > Value').textContent
  return studyUrl.replace(/.*\/studies\//, '')
}

export function* linkStudyToPatient(study) {
  try {
    const { studies: { value: studies } } = yield select(Patient.selectData)
    const studyId = yield getStudyIdFromXml(study)
    if (!studies.includes(studyId)) {
      yield put(Patient.linkStudy(studyId))
      yield put(Dicom.retrieveStudies({ studies: [studyId] }))
    }
    yield put(Dicom.success())
  } catch (error) {
    yield put(Dicom.apiError(error.message))
  }
}

export function* uploadAndLinkStudy({ payload: { file } }) {
  try {
    const study = yield uploadStudy(file)
    yield linkStudyToPatient(study)
  } catch (error) {
    yield put(Dicom.apiError(error.message))
  }
}

export function* removeStudy({ payload: studyId }) {
  try {
    const patientId = yield select(Patient.selectPatientId)
    const accessToken = yield select(Dicom.selectJWTToken)
    const {
      isOtherPatientWithDicomStudyId: isSharedStudyDetected
    } = yield call(Api.removeStudy, patientId, studyId)
    if (!isSharedStudyDetected) {
      yield call(Api.removeRemoteStudy, accessToken, studyId)
    }
    yield put(Patient.unlinkStudy(studyId))
  } catch (error) {
    yield put(Dicom.apiError(error.message))
  }
}

export function* generateDownloadDicom({ payload: { fileName, studyUid } }) {
  try {
    const JWTToken = yield call(getJWTToken)

    yield call(Api.downloadDicom, JWTToken, fileName, studyUid, { signal: abortDownloadController.signal })
    yield put(Patient.setDownload({ studyId: studyUid, isLinkAvailable: false }))
    yield put(Dicom.setDownloadingZipName(fileName))
  } catch (error) {
    yield put(Dicom.apiError(error.message))
  }
}

export function* checkDownload({ payload: studyId }) {
  try {
    const { zipFileGenerated } = yield call(Api.checkDownload, studyId)
    if (zipFileGenerated) {
      yield put(Patient.setDownload({ studyId, isLinkAvailable: zipFileGenerated }))
      yield put(Dicom.setDownload({ studyId, isLinkAvailable: zipFileGenerated }))
      const fileName = yield select(Dicom.selectDownloadingZipName)
      window.location.href = `${apiUrl}/patients/download-dicom-study-zip?studies=${studyId}&zipFileName=${fileName}.zip`
      yield put(Dicom.stopDownloading(studyId))
    }
  } catch (error) {
    console.log(error)
    yield put(Dicom.apiError(error.message))
  }
}

export function* checkDownloads({ payload: studies }) {
  try {
    for (let studyId of studies) {
      const { zipFileGenerated } = yield call(Api.checkDownload, studyId)
      if (zipFileGenerated) {
        yield put(Dicom.setDownload({ studyId, isLinkAvailable: zipFileGenerated }))
        yield put(Patient.setDownload({ studyId, isLinkAvailable: zipFileGenerated }))
      }
    }
  } catch (error) {
    yield put(Dicom.apiError(error.message))
  }
}

export function* openViewerDicom({ payload: { url } }) {
  const JWTToken = yield call(getJWTToken)
  window.open(`${url}/?token=${JWTToken}`, '_blank')
}

export function* getJWTToken() {
  try {
    let JWTToken = yield select(Dicom.selectJWTToken)
    const tokenExpirationDate = yield select(Dicom.selectTokenExpirationDate)
    const remainingTime = (new Date(tokenExpirationDate) - new Date()) / 1000

    if (!JWTToken || remainingTime < 10) {
      const { access_token, expires_in } = yield call(Api.getJWTToken)
      const date = new Date()
      const token_expiration_date = date.setSeconds(date.getSeconds() + expires_in)
      yield put(Dicom.setJWTToken({ token: access_token, token_expiration_date }))

      JWTToken = access_token
    }

    return JWTToken
  } catch (error) {
    yield put(Dicom.apiError(error.message))
  }
}

export function* initUpload() {
  try {
    abortUploadController = new AbortController();
    const patientId = yield select(Patient.selectPatientId)
    yield put(Dicom.setPatientId(patientId))
  } catch (error) {
    yield put(Dicom.apiError(error.message))
  }
}

export function* initDownload() {
  try {
    abortDownloadController = new AbortController();
  } catch (error) {
    yield put(Dicom.apiError(error.message))
  }
}

const DicomSagas = function* () {
  yield takeLatest(`${Dicom.retrieveStudies}`, retrieveStudies)
  yield takeEvery(`${Dicom.uploadAndLinkStudy}`, uploadAndLinkStudy)
  yield takeLatest(`${Dicom.removeStudy}`, removeStudy)
  yield takeLatest(`${Dicom.openViewerDicom}`, openViewerDicom)
  yield takeLatest(`${Dicom.getJWTToken}`, getJWTToken)
  yield takeLatest(`${Dicom.initUpload}`, initUpload)
  yield takeLatest(`${Dicom.initDownload}`, initDownload)
  yield takeLatest(`${Dicom.abortUpload}`, abortUpload)
  yield takeLatest(`${Dicom.abortDownload}`, abortDownload)
  yield takeLatest(`${Dicom.downloadDicom}`, generateDownloadDicom)
  yield takeLatest(`${Dicom.checkDownload}`, checkDownload)
  yield takeLatest(`${Dicom.checkDownloads}`, checkDownloads)
};

export default DicomSagas;
