import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro'
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import * as Login from '../State/Login'
import * as Ui from './Ui'
import * as Styled from './Login.styled'
import LoginForm from './Ui/LoginForm'
import SEOTags from './SEOTags';
import * as Icon from './Icon';
import './Css/Modal.css';

const LoginComponent = () => {
  const loading = useSelector(Login.selectLoading);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    const confirmNewConnectionMessage = localStorage.getItem('confirmNewConnectionMessage');

    if (!confirmNewConnectionMessage) {
      setModalIsOpen(true);
    }
  }, [])

  return (
    <>
      <Modal
        open={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        center
        classNames={{
          modal: 'customModal',
        }}
        closeIcon={<Icon.Cross/>}
      >
        <ModalTitle>Nouvelle page de connexion</ModalTitle>
        <ModalDescription>Une nouvelle page de connexion sera bientôt disponible pour vous permettre d’utiliser toutes les solutions SKEZI avec un seul compte !</ModalDescription>
        <ModalDescription>Pour des raisons de sécurité, nous vous demanderons <b>dès la semaine du 26 août</b> de renforcer votre mot de passe.</ModalDescription>
        <ModalButtons>
          <ModalCloseButton
            isGreen
            onClick={() => setModalIsOpen(false)}
          >
            Fermer
          </ModalCloseButton>
          <Ui.Button.BasicButton
            isFilled
            isGreen
            onClick={() => {
              localStorage.setItem('confirmNewConnectionMessage', true);
              setModalIsOpen(false)
            }}
          >
            Compris
          </Ui.Button.BasicButton>
        </ModalButtons>
      </Modal>
      <SEOTags
        title="Connectez-vous - Skemeet"
      />
      <Styled.LoginTeleExpertiseContainer>
        <Ui.PortailLayout.Wrapper>
          <Ui.PortailHeader noButtons />
          <Ui.PortailLayout.PortalMain $loading={loading}>
            {loading
              ? <Ui.Global.Loader />
              : <>
                <Ui.PortailLayout.PortalContainer>
                  <Ui.PortailLayout.PortalContentContainer>
                    <Ui.PortailLayout.FlexPage>
                      <Ui.PortailLayout.Content>
                        <LoginForm />
                      </Ui.PortailLayout.Content>
                    </Ui.PortailLayout.FlexPage>
                  </Ui.PortailLayout.PortalContentContainer>
                  <Ui.PortailLayout.Footer />
                </Ui.PortailLayout.PortalContainer>
              </>
            }
          </Ui.PortailLayout.PortalMain>
        </Ui.PortailLayout.Wrapper>
      </Styled.LoginTeleExpertiseContainer>
    </>
  )
}

const ModalTitle = styled.h2`
  margin-top: 1rem;
  text-align: center;
  color: #214152;
  font-size: 1.3rem;
`

const ModalDescription = styled.p`
  text-align: center;
  color: #214152;
  font-size: 1rem;
`

const ModalButtons = styled.div`
  display: flex;

  button {
    flex-grow: 1;
  }
`

const ModalCloseButton = styled(props => <Ui.Button.BasicButton {...props} />)`
  margin-right: 1rem;
`

export default LoginComponent;
