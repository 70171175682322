import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
  id: null,
  data: {
    from: { value: '', errors: [], validationRules: ['notEmpty', 'email'], },
    name: { value: '', errors: [], validationRules: [], },
    subject: { value: 'Nouveau message depuis ShareConfrère', errors: [], validationRules: [], },
    content: { value: '', errors: [], validationRules: ['notEmpty'], },
  },
  search: {
    patients: { value: '' },
    recipients: { value: '' },
  },
  messagesNum: 0,
  notificationsNum: 0,
  pending: false,
  loading: false,
  success: false,
  error: false,
  errorMessage: null,
  isEditMode: false,
  currentUser: null,
}

const slice = createSlice({
  name: 'contactUs',
  initialState: INITIAL_STATE,
  reducers: {

    updateField: (state, { payload: { name, value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value,
          errors,
        },
      },
      error: false,
      errorMessage: null,
    }),

    success: state => ({
      ...state,
      loading: false,
      pending: false,
      error: false,
      success: true,
    }),

    invalidate: (state, { payload }) => ({
      ...state,
      pending: false,
      error: true,
      errorMessage: payload,
    }),

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
      success: false,
    }),

    send: (state, action) => ({
      ...state,
      pending: true,
      error: false,
      success: false,
    }),

    clean: state => INITIAL_STATE,
  },
})

export const {
  updateField,
  success,
  invalidate,
  apiError,
  clean,
  send,
} = slice.actions

export const selectData = state => state.contactUs.data

export const selectSuccess = state => state.contactUs.success

export const selectLoading = state => state.contactUs.loading

export default slice.reducer
