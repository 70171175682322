export const setCookie = (cookieName, cookieValue, hourToExpire) => {
  const domain = window.location.hostname === 'localhost'
                                              ? 'localhost'
                                              : window.location.hostname.match('.skemeet.dev')
                                                ? '.skemeet.dev'
                                                : '.skemeet.io';
  let date = new Date();
  date.setTime(date.getTime()+(hourToExpire*60*60*1000));
  document.cookie = `${cookieName}=${cookieValue}; domain=${domain}; path=/; expires = ${date.toGMTString()}`;
}

export const getCookie = (name) => {
  var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
  result && (result = JSON.parse(result[1]));
  return result;
 }

 export const deleteCookie = (name) => {
  const domain = window.location.hostname === 'localhost'
                                              ? 'localhost'
                                              : window.location.hostname.match('.skemeet.dev')
                                                ? '.skemeet.dev'
                                                : '.skemeet.io';
  document.cookie = `${name}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/; domain=${domain}`;
}
