import { createSlice } from '@reduxjs/toolkit';
import { uniqById } from '../Util/Object';
import { dateToString, normalizeEmail } from '../Util/Format';
import {
  saveField as commonSaveField,
  setEditField as commonSetEditField,
  restoreInitialValue as commonRestoreInitialValue,
  updateInitialValue as commonUpdateInitialValue,
} from '../Util/InlineEditActions';

export const SECTION_SUMMARY = 'summary'
export const SECTION_TELE_EXPERTISE = 'teleExpertise'
export const SECTION_COLLEAGUE = 'colleague'
export const SECTION_COMMENT = 'comment'

export const INITIAL_STATE = {
  data: {
    firstname: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: ['notEmpty'], },
    lastname: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: ['notEmpty'], },
    fullname: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: [], },
    gender: { value: 'male', errors: [], validationRules: ['notEmpty'], },
    birthdate: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: ['notEmpty', 'dateTimeFormat', 'isPastDate'], },
    fullBirthdate: { value: '', errors: [], validationRules: [] },
    diagnostic: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: [], },
    anamneses: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: [] },
    medicalId: { value: '', initialValue: '', isFieldEdit: false, errors: [], validationRules: [] },
    doctor: { value: [], initialValue: '', isFieldEdit: false, errors: [], validationRules: [] },
    ownerTitle: { value: [], errors: [], validationRules: [] },
    expertises: { value: [], errors: [], validationRules: [] },
    defaultOpinions: { value: [], errors: [], validationRules: [] },
    keywordsNotFound: { value: [], errors: [], validationRules: [] },
    email: { value: '', errors: [], initialValue: '', isFieldEdit: false, validationRules: ['email'] },
    patientCode: { value: '', errors: [], validationRules: [] },
    cohortsPatientStepsByCustomFieldSectionId: { value: {}, errors: [], validationRules: [] },
    patientStepDates: { value: {}, errors: [], validationRules: [] },
    staffSessionsLastIdByStaffId: { value: {}, errors: [], validationRules: [], },
    studies: { value: [], errors: [], validationRules: [], },
    downloads: { value: {}, errors: [], validationRules: [], },
    personalOpinionId: { value: {}, errors: [], validationRules: [], },
    createdAt: { value: '', errors: [], validationRules: [] },
    updatedAt: { value: '', errors: [], validationRules: [] },
    createdFromPortal: { value: false, errors: [], validationRules: [] },
    noLinkedTeleExpertise: { value: false, errors: [], validationRules: [] },
    patientManagers: { value: [], errors: [], validationRules: [] },
    permissions: {
      value: {
        _canBe: { seenWithTeleExpertiseLimitPatient: true, deleted: false },
        _canBeModified: {}
      }, errors: [], validationRules: []
    },
  },
  currentSection: SECTION_SUMMARY,
  dicomBinary: null,
  requests: {},
  imageries: [],
  documents: [],
  expertisesData: [],
  availableExpertises: [],
  keywordsNotFound: [],
  expertisesSearchResults: [],
  pending: false,
  loading: false,
  success: false,
  updateIdentitySuccess: false,
  creationSuccess: false,
  deleted: false,
  error: false,
  errorMessage: null,
  warningMessage: null,
  infoMessage: null,
  isEditMode: {
    identity: false,
    patientFile: false,
    opinions: false,
    comments: false,
    attachments: false,
    extraInformations: false,
    email: false,
    fullName: false,
    customFields: false,
    colleagues: false,
    patientManagers: false,
  },
  coOwnershipRequestSent: false,
  patientId: null,
  currentUser: null,
  search: {
    expertises: { value: '', loading: false },
    opinions: { value: '', loading: false },
    defaultOpinions: { value: '', loading: false },
    staffs: { value: '', loading: false },
    cohorts: { value: '', loading: false },
    confreres: { value: '', loading: false },
    patientManagers: { value: '', loading: false },
  },
  JWTToken: null,
  existingOwnedPatient: false,
  patientExists: false,
}

const slice = createSlice({
  name: 'patient',
  initialState: INITIAL_STATE,
  reducers: {
    checkExpertiseFromList: (state, { payload: { type, id, toggle = true } }) => ({
      ...state,
      expertisesData: [
        ...state.expertisesData,
        state.availableExpertises.find(el => `${el.type}_${el.id}` === `${type}_${id}`)
      ],
      pending: true,
    }),

    uncheckExpertiseFromList: (state, { payload: { type, id, toggle = false } }) => ({
      ...state,
      expertisesData: state.expertisesData
        .filter(el => `${el.type}_${el.id}` !== `${type}_${id}`),
      pending: true,
    }),

    setPending: (state, { payload }) => ({
      ...state,
      pending: payload,
    }),

    updateField: (state, { payload: { name, value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value: name === 'email' ? normalizeEmail(value) : value,
          errors,
        },
      },
      error: false,
      errorMessage: null,
    }),

    updatePatientStepDate: (state, {
      payload: {
        value: {
          formId,
          date,
          patientStepId,
        },
        errors = []
      } }) => ({
        ...state,
        data: {
          ...state.data,
          patientStepDates: {
            ...state.data.patientStepDates,
            value: {
              [formId]: state.data.patientStepDates.value[formId]
                .map(step =>
                  step.patientStepId === patientStepId
                    ? { ...step, date, patientStepId, }
                    : step
                )
            },
            errors,
          },
          cohortsPatientStepsByCustomFieldSectionId: {
            ...state.data.cohortsPatientStepsByCustomFieldSectionId,
            value: Object
              .entries(state.data.cohortsPatientStepsByCustomFieldSectionId.value)
              .reduce((acc, [key, {
                patientStepId: stepId,
                patientStepTitle,
                date: patientStepDate
              }]) => ({
                ...acc,
                [key]: ({
                  patientStepTitle,
                  patientStepId: stepId,
                  date: String(stepId) === String(patientStepId)
                    ? dateToString(date)
                    : patientStepDate
                })
              }), {})
          }
        },
        error: false,
        errorMessage: null,
      }),

    selectExpertise: (state, { payload: { value, errors = [] } }) => ({
      ...state,
      data: {
        ...state.data,
        expertises: {
          ...state.data.expertises,
          value: [
            ...state.data.expertises.value,
            value
          ].filter(uniqById),
          errors,
        },
      },
      error: false,
      errorMessage: null,
    }),

    updateDoctor: (state, { payload: { colleague: { colleagueId }, formerDoctor } }) => ({
      ...state,
      data: {
        ...state.data,
        doctor: {
          ...state.data.doctor,
          value: Number(colleagueId)
        },
        patientManagers: {
          ...state.data.patientManagers,
          value: [
            ...state.data.patientManagers.value.filter(e => e !== colleagueId),
            formerDoctor
          ]
        },
        permissions: {
          ...state.data.permissions,
          value: {
            ...state.data.permissions.value,
            _canBe: {
              ...state.data.permissions.value._canBe,
              deleted: false,
            },
          }
        }
      }
    }),

    toggle: (state, { payload: { value, toggled, name } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          value: toggled
            ? [...state.data[name].value, value]
            : state.data[name].value.filter(e => value.id !== e.id),
        },
      },
      search: {
        ...state.search,
        [name]: {
          ...state.search[name],
          value: '',
        }
      },
      error: false,
      errorMessage: null,
    }),

    setQuery: (state, { payload: { name, value } }) => ({
      ...state,
      search: {
        ...state.search,
        [name]: {
          ...state.search[name],
          value
        }
      }
    }),

    send: state => ({
      ...state,
      pending: true,
      error: false,
      success: false,
      data: Object
        .entries(state.data)
        .reduce((acc, [name, field]) => ({
          ...acc,
          [name]: { ...field, errors: [] },
        }), state.data),
    }),

    sendGender: state => ({
      ...state,
    }),

    setEditMode: (state, { payload: { target, value } }) => ({
      ...state,
      isEditMode: {
        ...state.isEditMode,
        [target]: value,
      }
    }),

    updateAll: state => ({
      ...state,
      pending: true,
      error: false,
      success: false,
    }),

    resetCreationFlags: (state) => ({
      ...state,
      creationSuccess: false,
      existingOwnedPatient: false,
    }),

    creationSuccess: (state, { payload: patientId }) => ({
      ...state,
      pending: false,
      error: false,
      creationSuccess: true,
      patientId,
    }),

    existingOwnedPatient: (state, { payload: patientId }) => ({
      ...state,
      pending: false,
      error: false,
      existingOwnedPatient: true,
      patientId,
    }),

    updateIdentitySuccess: (state, { payload: id }) => ({
      ...state,
      pending: false,
      error: false,
      updateIdentitySuccess: true,
      patientId: id
    }),

    success: (state, { payload: id }) => ({
      ...state,
      pending: false,
      error: false,
      success: true,
      updateIdentitySuccess: false,
      patientId: id
    }),

    deleted: state => ({
      ...state,
      pending: false,
      error: false,
      deleted: true,
    }),

    invalidate: (state, { payload: { message } }) => ({
      ...state,
      pending: false,
      error: true,
      errorMessage: message,
    }),

    setInfoMessage: (state, { payload: message }) => ({
      ...state,
      infoMessage: message,
    }),

    apiError: (state, action) => ({
      ...state,
      errorMessage: action.payload,
      pending: false,
      error: true,
      success: false,
    }),

    receivedAvailableExpertises: (state, { payload: { teleExpertises, keywordsNotFound } }) => ({
      ...state,
      availableExpertises: [
        ...state.availableExpertises,
        ...teleExpertises
      ].filter(uniqById),
      keywordsNotFound,
      loading: false,
      search: {
        ...state.search,
        expertises: {
          ...state.search.expertises,
          loading: false
        }
      }
    }),

    clean: state => ({
      ...INITIAL_STATE,
      creationSuccess: state.creationSuccess,
      existingOwnedPatient: state.existingOwnedPatient
    }),

    cleanAll: state => ({
      ...INITIAL_STATE,
    }),

    addAvailableExpertise: (state, { payload }) => ({
      ...state,
      availableExpertises: [
        ...state.availableExpertises,
        payload,
      ],
      search: {
        ...state.search,
        expertises: {
          ...state.search.expertises,
          loading: false
        }
      }
    }),

    fetchAvailableExpertises: state => ({
      ...state,
      search: {
        ...state.search,
        expertises: {
          ...state.search.expertises,
          loading: true
        }
      },
      availableExpertises:
        Array.isArray(state.data.expertises.value)
          ? state.availableExpertises.filter(exp =>
            state.data.expertises.value.find(expertise =>
              expertise.type === exp.type && Number(expertise.id) === Number(exp.id)
            ))
          : []
    }),

    validateData: (state, { payload: { name } }) => ({
      ...state,
      data: {
        ...state.data,
        [name]: {
          ...state.data[name],
          errors: []
        }
      }
    }),

    fetch: (state, { payload: { patientId } }) => ({
      ...state,
      loading: true,
      patientId
    }),

    deletePatient: state => ({
      ...state,
      pending: true,
    }),

    received: (state, { payload }) => ({
      ...state,
      data: Object
        .entries(payload)
        .reduce((acc, [name, value]) => ({
          ...acc,
          [name]: typeof value === 'string'
            ? ({
              ...state.data[name],
              value,
              initialValue: value,
            })
            : ({
              ...state.data[name],
              value,
            }),
        }), state.data),
      expertisesData: Object
        .entries(payload.expertises)
        .reduce((acc, [type, entries]) =>
          [
            ...acc,
            ...entries.map(exp => ({
              ...exp,
              type,
            }))
          ], []),
      loading: false,
    }),

    receivedQuestionnaireDates: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        patientStepDates: {
          ...state.data.patientStepDates,
          value: payload
        },
      },
    }),

    receivedCohortsPatientStepsByCustomFieldSectionId: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        cohortsPatientStepsByCustomFieldSectionId: {
          ...state.data.cohortsPatientStepsByCustomFieldSectionId,
          value: payload
        },
      },
    }),


    receivedExpertises: (state, { payload: { staffs, cohorts, opinions } }) => ({
      ...state,
      data: {
        ...state.data,
        expertises: {
          ...state.data.expertises,
          value: {
            staffs: [
              ...state.data.expertises.value.staffs,
              ...staffs,
            ],
            opinions: [
              ...state.data.expertises.value.opinions,
              ...opinions,
            ],
            cohorts: [
              ...state.data.expertises.value.cohorts,
              ...cohorts,
            ],
          }
        },
      },
    }),

    receivedRequests: (state, { payload }) => ({
      ...state,
      requests: payload,
    }),

    receivedImageries: (state, { payload }) => ({
      ...state,
      imageries: payload,
    }),

    receivedDocuments: (state, { payload }) => ({
      ...state,
      documents: payload,
    }),

    removeImage: (state, { payload }) => ({
      ...state,
      imageries: state
        .imageries
        .filter(({ id }) => id !== payload),
    }),

    removeDocument: (state, { payload }) => ({
      ...state,
      documents: state
        .documents
        .filter(({ id }) => id !== payload),
    }),

    removePublicDocument: (state, { payload }) => ({
      ...state,
      documents: state
        .documents
        .filter(({ id }) => id !== payload),
    }),

    updateOpinions: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        defaultOpinions: {
          ...state.data.defaultOpinions,
          value: payload.map(el => el.id)
        }
      },
    }),

    addOpinion: (state, { payload: { id } }) => ({
      ...state,
      data: {
        ...state.data,
        defaultOpinions: {
          ...state.data.defaultOpinions,
          value: [
            ...state.data.defaultOpinions.value,
            id
          ]
        }
      },
    }),

    removeOpinion: (state, { payload: { id } }) => ({
      ...state,
      data: {
        ...state.data,
        defaultOpinions: {
          ...state.data.defaultOpinions,
          value: state.data.defaultOpinions.value.filter(opinionId => opinionId !== id)
        }
      },
    }),

    addDefaultOpinion: (state, { payload: { id } }) => ({
      ...state,
      data: {
        ...state.data,
        defaultOpinions: {
          ...state.data.defaultOpinions,
          value: state.data.defaultOpinions.value.includes(id)
            ? state.data.defaultOpinions.value
            : [
              ...state.data.defaultOpinions.value,
              id
            ]
        }
      },
    }),

    addPatientManager: (state, { payload: { patientId, colleagueId } }) => ({
      ...state,
      data: {
        ...state.data,
        patientManagers: {
          ...state.data.patientManagers,
          value: [
            ...state.data.patientManagers.value,
            colleagueId
          ]
        }
      }
    }),

    removePatientManager: (state, { payload: { patientId, colleagueId } }) => ({
      ...state,
      data: {
        ...state.data,
        patientManagers: {
          ...state.data.patientManagers,
          value:
            state.data.patientManagers.value
              .filter(id => id !== colleagueId)
        }
      }
    }),

    setCurrentSection: (state, { payload: section }) => ({
      ...state,
      currentSection: section,
    }),

    setWarningMessage: (state, { payload: message }) => ({
      ...state,
      warningMessage: message
    }),

    sendEmail: state => ({
      ...state,
    }),

    linkStudy: (state, { payload: studyId }) =>
      state.data.studies.value.some(id => id === studyId)
        ? state
        : ({
          ...state,
          data: {
            ...state.data,
            studies: {
              ...state.data.studies,
              value: [
                ...state.data.studies.value,
                studyId
              ]
            }
          }
        }),

    unlinkStudy: (state, { payload: studyId }) => ({
      ...state,
      data: {
        ...state.data,
        studies: {
          ...state.data.studies,
          value: state.data.studies.value.filter(id => id !== studyId)
        },
        downloads: {
          ...state.data.downloads,
          value: Object.entries(state.data.downloads.value)
            .filter(([id, _]) => id !== studyId)
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
        }
      }
    }),

    setDownload: (state, { payload: { studyId, isLinkAvailable } }) => ({
      ...state,
      data: {
        ...state.data,
        downloads: {
          ...state.data.downloads,
          value: {
            ...state.data.downloads.value,
            [studyId]: isLinkAvailable
          }
        }
      }
    }),

    setPatientExists: (state, { payload }) => ({
      ...state,
      patientExists: payload.existing,
      patientId: payload.patientId,
      requestedDoctor: payload.requestedDoctor,
    }),

    askPatientCoOwnership: (state) => ({
      ...state,
      coOwnershipRequestSent: false,
    }),

    setPatientOwnershipSent: (state) => ({
      ...state,
      coOwnershipRequestSent: true,
    }),

    createPatient: state => ({ ...state }),
    saveCustomField: (state, { payload }) => ({ ...state }),
    saveStudies: state => ({ state }),
    commonSaveField,
    commonSetEditField,
    commonRestoreInitialValue,
    commonUpdateInitialValue,
  }
})

export const {
  send,
  success,
  deleted,
  invalidate,
  updateField,
  apiError,
  expertises,
  receivedAvailableExpertises,
  validateData,
  checkExpertiseFromList,
  uncheckExpertiseFromList,
  selectExpertise,
  fetchAvailableExpertises,
  fetch,
  fetchAttachments,
  addAvailableExpertise,
  clean,
  received,
  receivedRequests,
  receivedImageries,
  receivedDocuments,
  updateAll,
  deletePatient,
  removeImage,
  removeDocument,
  setEditMode,
  addDefaultOpinion,
  updateOpinions,
  addOpinion,
  removeOpinion,
  setQuery,
  toggle,
  setCurrentSection,
  setWarningMessage,
  setInfoMessage,
  updateDoctor,
  sendEmail,
  updatePatientStepDate,
  linkStudy,
  unlinkStudy,
  saveStudies,
  setDownload,
  sendGender,
  creationSuccess,
  existingOwnedPatient,
  updateIdentitySuccess,
  setPending,
  saveCustomField,
  receivedQuestionnaireDates,
  receivedCohortsPatientStepsByCustomFieldSectionId,
  receivedExpertises,
  removePublicDocument,
  resetCreationFlags,
  setPatientExists,
  createPatient,
  addPatientManager,
  removePatientManager,
  askPatientCoOwnership,
  setPatientOwnershipSent,
  cleanAll,
  commonSaveField: saveField,
  commonSetEditField: setEditField,
  commonRestoreInitialValue: restoreInitialValue,
  commonUpdateInitialValue: updateInitialValue,
} = slice.actions

export const selectData = state => state.patient.data
export const selectSearch = state => state.patient.search
export const selectSuccess = state => state.patient.success
export const selectPending = state => state.patient.pending
export const selectColleagues = state => state.patient.data.colleagues
export const selectPatientId = state => state.patient.patientId
export const selectRequests = state => state.patient.requests
export const selectImageries = state => state.patient.imageries
export const selectDocuments = state => state.patient.documents
export const selectSelectedExpertises = state => state.patient.data.expertises
export const selectAvailableExpertises = state => state.patient.availableExpertises
export const selectExpertisesData = state => state.patient.expertisesData
export const selectKeywordsNotFound = state => state.patient.data.keywordsNotFound
export const selectIsEditMode = state => state.patient.isEditMode
export const selectCreationSuccess = state => state.patient.creationSuccess
export const selectErrorMessage = state => state.patient.errorMessage
export const selectHasTeleExpertiseLinked = state => state.patient.data.expertises?.value.length > 0
export const selectPatientExists = state => state.patient.patientExists
export const selectExistingOwnedPatient = state => state.patient.existingOwnedPatient
export const selectCoOwnershipRequestsent = state => state.patient.coOwnershipRequestSent
export const selectRequesteDoctor = state => state.patient.requestedDoctor

export default slice.reducer
