import { createSlice } from '@reduxjs/toolkit'

export const SECTION_ABOUT = { label: 'A propos', slug: 'a-propos' };
export const SECTION_CREATE_PATIENT = { label: 'Adresser un patient', slug: 'nouveau-patient' };
export const SECTION_DOCUMENTS = { label: 'Documents', slug: 'documents' };
export const SECTION_LOGIN = { label: 'Se connecter', slug: 'connexion' };
export const SECTION_REGISTER = { label: 'S\'inscrire', slug: 'inscription' };

export const INITIAL_STATE = {
  selectedExpertise: {
    id: null,
    name: null,
    type: null,
    pictureFileName: null,
    profilePicture: null,
    tags: '',
    owner: '',
    disciplines: '',
    thePersonal: false,
    isVideoconferencingToday: false,
    portalHeaderImage: null,
    portalHeaderImageAlign: 1,
    portalHeaderImageStretch: false,
    portalDocuments: [],
    portalAbout: '',
  },
  selectedVisioconference: {
    id: null,
    name: null,
    type: null,
  },
  credentials: {
    email: {
      value: '',
      errors: [],
      validationRules: ['notEmpty', 'email'],
    },
    password: {
      value: '',
      errors: [],
      validationRules: ['notEmpty'],
    },
  },
  errored: false,
  sending: false,
  loading: false,
  codeSent: false,
  codeResent: false,
  loggedIn: false,
  codeFilling: false,
  canInstallTotp: false,
  installTotp: false,
  errorMessage: '',
  staffs: [],
  cohorts: [],
  opinions: [],
  otp: {
    data: {
      code: {
        value: '',
        errors: [],
        validationRules: ['notEmpty'],
      },
    },
    mode: '',
    email: null,
  },
  currentUser: null,
  failure: false,
  reset: false,
  isLogged: true,
}

const slice = createSlice({
  name: 'login',
  initialState: INITIAL_STATE,
  reducers: {

    receivedUser: (state, { payload: currentUser }) => ({
      ...state,
      currentUser,
      failure: false,
    }),

    fetchCurrentUser: (state) => ({
      ...state,
    }),

    selectTeleExpertise: (state, { payload: {
      teleExpertiseId,
      teleExpertiseName = '',
      teleExpertiseType = 'staff',
      teleExpertisePicture = '',
      thePersonal = false,
      teleExpertiseDescription = '',
      teleExpertiseTags = '',
      teleExpertiseOwner = '',
      teleExpertiseOwnerProfilePicture = '',
      teleExpertiseDisciplines = '',
      teleExpertiseOwnerSpeciality = '',
      teleExpertiseOwnerCity = '',
      teleExpertiseIsVisioToday = false,
      staffNextSessionDate = '',
      staffNextSessionHour = '',
      portalHeaderImage,
      portalHeaderImageAlign,
      portalHeaderImageStretch,
      portalDocuments,
      portalAbout,
    } }) => ({
      ...state,
      selectedExpertise: {
        id: teleExpertiseId,
        name: teleExpertiseName,
        type: teleExpertiseType,
        pictureFileName: teleExpertisePicture,
        profilePicture: teleExpertiseOwnerProfilePicture,
        owner: teleExpertiseOwner,
        tags: Array.isArray(teleExpertiseTags) ? teleExpertiseTags.join(',') : teleExpertiseTags,
        disciplines: Array.isArray(teleExpertiseDisciplines) ? teleExpertiseDisciplines.join(',') : teleExpertiseDisciplines,
        description: teleExpertiseDescription,
        ownerSpeciality: teleExpertiseOwnerSpeciality,
        ownerCity: teleExpertiseOwnerCity,
        thePersonal,
        isVideoconferencingToday: teleExpertiseIsVisioToday,
        staffNextSessionDate: staffNextSessionDate,
        staffNextSessionHour: staffNextSessionHour !== '00:00' ? staffNextSessionHour : '',
        portalHeaderImage,
        portalHeaderImageAlign,
        portalHeaderImageStretch,
        portalDocuments,
        portalAbout,
      },
      selectedVisioconference: {
        id: null,
        name: null,
        type: null,
      },
      oneMoreTry: false
    }),

    selectVisioconference: (state, { payload: { teleExpertiseId, teleExpertiseName, teleExpertiseType } }) => ({
      ...state,
      selectedVisioconference: {
        id: teleExpertiseId,
        name: teleExpertiseName,
        type: teleExpertiseType,
      },
      selectedExpertise: {
        id: null,
        name: null,
        type: null,
      }
    }),

    updateCredential: (state, { payload: { name, value } }) => ({
      ...state,
      credentials: {
        ...state.credentials,
        [name]: {
          ...state.credentials[name],
          value,
          errors: [],
        }
      },
      errored: false,
    }),

    sendCredentials: state => ({ ...state, sending: true, codeSent: true, errorMessage: '' }),

    checkCode: state => ({ ...state, sending: true, errorMessage: '' }),

    resendCode: state => ({ ...state, sending: true, codeSent: false, codeResent: false, errorMessage: '' }),

    setCodeResent: state => ({ ...state, sending: false, codeResent: true }),

    invalidateCredential: (state, { payload: { name, errors } }) => ({
      ...state,
      credentials: {
        ...state.credentials,
        [name]: {
          ...state.credentials[name],
          errors,
        },
      },
      sending: false,
      errored: true,
    }),

    error: (state, { payload }) => ({
      ...state,
      errored: true,
      errorMessage: payload,
      sending: false,
      resetting: false,
    }),

    updateOtpInfo: (state, { payload }) => ({
      ...state,
      sending: false,
      errored: false,
      codeFilling: true,
      otp: {
        ...state.otp,
        ...payload,
      }
    }),

    setCanInstallTotp: (state, { payload }) => ({
      ...state,
      canInstallTotp: payload,
    }),

    setInstallTotp: (state, { payload }) => ({
      ...state,
      installTotp: payload,
    }),

    fetchOtpMode: (state, { payload }) => ({
      ...state,
      codeSent: true,
      otp: {
        ...state.otp,
        mode: payload,
      }
    }),

    updateOtpMode: (state, { payload }) => ({
      ...state,
      codeFilling: true,
      otp: {
        ...state.otp,
        mode: payload,
      }
    }),

    updateOtpCode: (state, { payload }) => ({
      ...state,
      loggedIn: false,
      sending: false,
      errored: false,
      otp: {
        ...state.otp,
        data: {
          ...state.otp.data,
          code: {
            ...state.otp.data.code,

            value: payload,
            errors: [],
          }
        }
      }
    }),

    invalidateOtpCode: (state, { payload: { errors } }) => ({
      ...state,
      loggedIn: false,
      sending: false,
      errored: true,
      otp: {
        ...state.otp,
        data: {
          ...state.otp.data,
          code: {
            ...state.otp.data.code,
            errors: [...state.otp.data.code.errors, errors],
          }
        }
      }
    }),

    success: state => ({
      ...state,
      loggedIn: true,
      sending: false,
      loading: false,
    }),

    failure: state => ({
      ...state,
      failure: true,
    }),

    resetPassword: state => ({
      ...state,
      resetting: true,
      reset: false,
    }),

    resetPasswordSuccess: state => ({
      ...state,
      resetting: false,
      reset: true,
    }),

    fetchCustomPageData: (state, payload) => ({
      ...state,
      loading: true,
    }),

    logOut: (state) => ({
      ...state,
    }),

    removeProfilePicture: state => ({
      ...state,
      currentUser: {
        ...state.currentUser,
        profilePicture: ''
      }
    }),
    updateProfilePicture: (state, { payload }) => ({
      ...state,
      currentUser: {
        ...state.currentUser,
        profilePicture: payload
      }
    }),

    updateActiveSubscription: (state, { payload: { unlockTeleExpertise, unlockTeleExpertiseRemaining } }) => ({
      ...state,
      currentUser: {
        ...state.currentUser,
        activeSubscription: {
          ...state.currentUser.activeSubscription,
          unlockTeleExpertise,
          unlockTeleExpertiseRemaining
        },
      }
    }),

    updateIsSecretHandleExpired: (state, { payload }) => ({
      ...state,
      currentUser: {
        ...state.currentUser,
        isSecretHandleExpired: payload
      }
    }),

    oneMoreLoginTry: (state, { payload }) => ({
      ...state,
      oneMoreTry: payload,
    }),

    checkNewDeployment: state => ({ ...state }),

    clean: state => INITIAL_STATE,
  }
})

export const {
  selectTeleExpertise,
  selectVisioconference,
  fetchOtpMode,
  updateCredential,
  updateOtpInfo,
  updateOtpCode,
  updateOtpMode,
  setCanInstallTotp,
  setInstallTotp,
  sendCredentials,
  checkCode,
  resendCode,
  setCodeResent,
  codeResent,
  invalidateCredential,
  invalidateOtpCode,
  error,
  success,
  receivedUser,
  currentUser,
  fetchCurrentUser,
  failure,
  resetPassword,
  resetPasswordSuccess,
  fetchCustomPageData,
  logOut,
  checkNewDeployment,
  clean,
  removeProfilePicture,
  updateProfilePicture,
  updateActiveSubscription,
  updateIsSecretHandleExpired,
  oneMoreLoginTry,
} = slice.actions

export const selectedErrorMessage = state => state.login.errorMessage

export const selectedExpertise = state => state.login.selectedExpertise

export const selectedVisioconference = state => state.login.selectedVisioconference

export const selectCredentials = state => state.login.credentials

export const selectEmail = state => state.login.credentials.email

export const selectReset = state => state.login.reset

export const selectResetting = state => state.login.resetting

export const selectCodeSent = state => state.login.codeSent

export const selectResent = state => state.login.codeResent

export const selectIsFillingCode = state => state.login.codeFilling

export const selectOtpCode = state => state.login.otp.data.code

export const selectUser = state => state.login.currentUser

export const selectUserId = state => state.login.currentUser.id

export const selectFailure = state => state.login.failure

export const selectOtpInfo = state => ({
  mode: state.login.otp.mode,
  email: state.login.otp.email,
})

export const selectOtpMode = state => state.login.otp.mode

export const selectCanInstallTotp = state => state.login.canInstallTotp

export const selectInstallTotp = state => state.login.installTotp

export const selectLoading = state => state.login.loading

export const selectOneMoreTry = state => state.login.oneMoreTry

export default slice.reducer
