import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Login from '../State/Login'
import * as MessageList from '../State/MessageList'
import {
  privateSitePrefix,
  publicHostsTeleExpertisePortal,
  publicHostsCohortPatient,
  establishmentsPortalPro,
  authUrl,
  privateUrl,
  serverEnv,
} from '../Util/Config'
import { useMatomo } from '@datapunt/matomo-tracker-react';

const { href, hostname, origin, host } = window.location
const subdomain = host.split('.')[0]

const FirewallComponent = ({ children }) => {
  const { trackPageView } = useMatomo()
  useEffect(() => {
    if (serverEnv === 'prod') {
      trackPageView()
    }
  }, [])

  const dispatch = useDispatch()
  const failure = useSelector(Login.selectFailure)
  const [firstLoad, setFirstLoad] = useState(true)
  const currentUser = useSelector(Login.selectUser)

  useEffect(() => {
    if (!currentUser) {

      dispatch(Login.fetchCurrentUser())
    }
  }, [dispatch, currentUser])

  useEffect(() => {
    dispatch(MessageList.fetchMessagesNum())
  }, [dispatch])

  return failure && !firstLoad
    ? window.location = authUrl
    : currentUser
      ? <>{children}</>
      : firstLoad
        ? setFirstLoad(false) && dispatch(Login.fetchCurrentUser())
        : null
}

export const FirewallPublic = ({ children }) => {
  const { trackPageView } = useMatomo()
  useEffect(() => {
    if (serverEnv === 'prod') {
      trackPageView()
    }
  }, [])

  return href.match('close-tab')
    ? window.close()
    : establishmentsPortalPro[href]
      ? window.location = `${href}portail/${subdomain}`
      : [...publicHostsTeleExpertisePortal, ...publicHostsCohortPatient].includes(hostname) ||
        establishmentsPortalPro[`${origin}/`] ||
        authUrl.includes(hostname)
        ? <>{children}</>
        : window.location = `${privateUrl}${privateSitePrefix}/patients`
}

export default FirewallComponent;
